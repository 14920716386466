$('.index-products__slide--left h3').on('click', function() {
	$('.index-products__slide--left h3').removeClass('__active');
	$(this).addClass('__active');
	let this_attr = $(this).attr('data-num');

	$('.index-products__slide--right').removeClass('__active');
	$('.index-products__slide--right[data-num="'+this_attr+'"]').addClass('__active');	


	$('.index-products__number').removeClass('__active');
	$('.index-products__number[data-num="'+this_attr+'"]').addClass('__active');
});


$('.about-benefits__item').hover(function() {
	$('.about-benefits__item').removeClass('__active');
	$(this).addClass('__active');
});


$('.md-menu__products-li').on('click', function() {
	$(this).toggleClass('__color');
	$(this).find('ul').toggleClass('__show');
});


$('.header-nav__showmenu').on('click', function() {
	$('.md-menu').addClass('__show');
});

$('.__close-menu').on('click', function() {
	$('.md-menu').removeClass('__show');
});

/*$('.md-menu__products-li > a').on('click', function(e) {
	e.stopPropagation();
});*/